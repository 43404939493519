import request from '@/utils/request'

export function updateSignItemStatus(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/updateSignStatus',
    method: 'POST',
    data,
  })
}

// 分页查询
export function getPageList(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/getPageList',
    method: 'POST',
    data,
  })
}

// 添加
export function add(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/add',
    method: 'POST',
    data,
  })
}

// 删除单个
export function deleteById(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/deleteById',
    method: 'POST',
    data,
  })
}

// 修改
export function updateById(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/updateById',
    method: 'POST',
    data,
  })
}

// 根据id查询单个
export function getById(data) {
  return request({
    url: 'marketing-web-api/admin/callcenter/skill/groups/getById',
    method: 'POST',
    data,
  })
}
